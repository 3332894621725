<template>
  <div class="alert alert-danger" role="alert">
    This code must be entered at
    <a :href="subsMgmtV2Url + '/redeem-router-coupon?coupon=' + checkoutData.coupon.routerCode" class="alert-link text-primary">
      our dashboard.
    </a> 
    Please follow the link to redeem this code.
  </div>
</template>
<script setup>
import { useCheckoutStore } from '@/stores/checkout';
import { storeToRefs } from 'pinia';

const subsMgmtV2Url = import.meta.env.VITE_SUBS_MGMT_V2_URL;
const checkoutStore = useCheckoutStore();
const { checkoutData } = storeToRefs(checkoutStore);
</script>
