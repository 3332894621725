const paymentIntegrationsUrl = import.meta.env.VITE_PAYMENT_INTEGRATIONS_URL;
const subsMgmtV2Url = import.meta.env.VITE_SUBS_MGMT_V2_URL;

export const createPurchase = async (checkoutData, recaptcha, tokenId, analytics, paymentType) => {
  const data = {
    currency: checkoutData.currency,
    email: checkoutData.email,
    plan: checkoutData.plan,
    quantity: checkoutData.quantity || 1,
    coupon: checkoutData.coupon.validatedCode,
    analytics,
    paymentType, // paypal or credit_card
  };

  // TODO: set these as conditional properties in data
  if (checkoutData.servers) {
    data.servers = {
      planCode: checkoutData.serverCode,
      quantity: checkoutData.servers,
    };
  }

  if (checkoutData.vatNumber) {
    data.vatNumber = checkoutData.vatNumber;
  }

  if (recaptcha) {
    data[`g-recaptcha-response`] = recaptcha;
  }

  if (tokenId) {
    data.tokenId = tokenId;
  }

  try {
    const response = await fetch(`${paymentIntegrationsUrl}/cart/subscriptions/new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return { error };
  }
};

export const getCoupon = async couponCode => {
  try {
    const response = await fetch(`${paymentIntegrationsUrl}/cart/coupons/get/${couponCode}`);
    return await response.json();
  } catch (err) {
    return { err };
  }
};

// to be deprecated when recurlyJS can catch invalid plan codes on its own
export const checkPlan = async planCode => {
  try {
    const response = await fetch(`${paymentIntegrationsUrl}/cart/plans/check/${planCode}`);
    return response;
  } catch (err) {
    return { err };
  }
};

export const verifyTokenUser = async () => {
  try {
    const response = await fetch(`${subsMgmtV2Url}/api/user`, { credentials: 'include' });
    return response;
  } catch (err) {
    return { err };
  }
};

export const getSubsMgmtPublicKey = async () => {
  try {
    const response = await fetch(`${subsMgmtV2Url}/.well-known/jwks.json`);
    return response.json();
  } catch (err) {
    return { err };
  }
};

export const logoutFromAccount = async () => {
  try {
    // invalidates session and token cookies
    await fetch(
      `${subsMgmtV2Url}/logout`,
      { method: 'GET', withCredentials: true, credentials: 'include' }
    );
    return { success: true };
  } catch (err) {
    return { err };
  }
};

export const checkPaid = async () => {
  try {
    const response = await fetch(`${subsMgmtV2Url}/api/account/paidStatus`,
      {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
      },
    );
    const result = await response.json();
    return result.paid;
  } catch (err) {
    return null;
  }
};
